import React from 'react';
import { Provider } from 'react-redux';

import PropTypes from 'prop-types';
//import reducers from './reducers';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './reduxStore';

//component created to disassociate the implementation of redux of the application,
// to be able to do integration tests with jest / enzyme
export const Root = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};
Root.propTypes = {
  children: PropTypes.object,
  initialState: PropTypes.object
};
